export default function MarketingResearch() {
    return (
      <div className="bg-gray-700 min-h-screen text-secondary font-sedan">
        {/* intro icon */}
        <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
          <img
            src="/images/database-banner.jpg"
            className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"
          />
  
          <div className="w-[30%] max-md:w-[80%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12 border-l-4 border-complementary h-fit px-8">
            <h1 className="text-6xl max-md:text-5xl leading-[75px] font-semibold text-black">
              Marketing Research: Your Strategic Advantage
            </h1>
          </div>
        </div>
  
        {/* Content Section */}
        <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center">
          <h1 className="text-5xl max-md:text-4xl font-bold text-center text-white">
            <span className="text-gray-200">Data-Driven Insights</span> for Informed Business Decisions
          </h1>
          <h2 className="text-2xl font-semibold text-center max-md:text-xl text-white">
            Valuable Marketing Reports and Statistics
          </h2>
          <p className="text-lg text-white text-center">
            At KYLR Media, we prioritize data-driven decision-making. Explore our latest marketing research reports and articles for valuable insights into global and local trends.
          </p>
  
          {/* Research Reports Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 justify-center items-start px-44 max-md:px-4 text-white">
            <div className="flex flex-col gap-8 text-lg max-md:text-sm">
              <img
                src="/images/nielsen-banner.jpg"
                className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px] rounded-md"
              />
              <h1>Key Insights from Nielsen's 2022 Global Marketing Trends Report:</h1>
              <ul className="list-disc list-inside">
                <li>The importance of brand awareness</li>
                <li>Leveraging data for personalized marketing strategies</li>
                <li>The necessity of integrated measurement</li>
                <li>Aligning your brand with your promise</li>
              </ul>
            </div>
  
            <div className="flex flex-col gap-8 text-lg max-md:text-sm">
              <img
                src="/images/socialmedia-banner.jpg"
                className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px] rounded-md"
              />
              <h1>
                Discover the $1 trillion opportunity in the APAC region by 2025. Explore the concept of "shoppertainment" for enriching shopping experiences.
              </h1>
            </div>
  
            <div className="flex flex-col gap-8 text-lg max-md:text-sm">
              <img
                src="/images/europeaneccomerce-banner.jpg"
                className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px] rounded-md"
              />
              <h1>
                Gain valuable insights for European markets with the European Ecommerce Report 2023. Additionally, explore Accenture's Consumer Behavior Report for understanding shifting consumer trends.
              </h1>
            </div>
  
            <div className="flex flex-col gap-8 text-lg max-md:text-sm">
              <img
                src="/images/flywheel-banner.jpg"
                className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px] rounded-md"
              />
              <h1>
                Learn about the Community Flywheel, a strategic approach for digital engagement and loyalty, as explored in McKinsey & Company's insightful article.
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
  