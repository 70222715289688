import { Link } from "react-scroll";

export default function Intro() {
  return (
    <div className="bg-black relative h-screen text-secondary flex justify-center items-center">
      <img
        src="/images/main-home-banner.jpg"
        className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"
        style={{
          backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))",
        }}
      />

      <div className="w-[40%] max-md:w-[60%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12">
        <h1 className="text-5xl font-semibold text-slate-200">
          <span className="text-white">|</span> KLYR Media
        </h1>
        <h1 className="text-3xl max-md:text-xl font-semibold text-white">
          Elevating Brands, Delivering Excellence – Where Innovation Drives Success
        </h1>
        <Link
          to="Contact"
          smooth={true}
          duration={500}
          className="max-md:text-xl hover:scale-105 hover:border-white hover:cursor-pointer duration-500 border-gray-700 border-2 p-4 px-6 rounded-sm w-fit text-2xl text-white"
        >
          Get in Touch
        </Link>
      </div>
    </div>
  );
}