import { faColonSign, faDumbbell, faHeart, faHome, faLifeRing, faMedkit, faPerson, faPersonDress, faPersonDressBurst, faServer } from "@fortawesome/free-solid-svg-icons";
import Sector from "./Sector";

export default function Sectors(){
    return(
        <div className="bg-gray-700 relative min-h-screen text-secondary flex justify-center items-center">
            
            <div className=" justify-center items-center flex flex-col gap-12 p-12 ">
                <h1 className="text-5xl max-md:text-4xl font-bold text-gray-200">Sectors</h1>
                <h1 className="text-3xl max-md:text-xl font-semibold text-complementary">Agency Marketing</h1>
                <div className="grid grid-cols-3 max-md:grid-cols-3 gap-40 max-md:gap-20 gap-y-8 items-start content-center text-white">
                    <Sector icon={faPersonDressBurst} field={"Fashion"}></Sector>
                    <Sector icon={faMedkit} field={"Medical Practice"}></Sector>
                    <Sector icon={faLifeRing} field={"Lifestyle"}></Sector>
                    <Sector icon={faHeart} field={"Hospitality"}></Sector>
                    <Sector icon={faHome} field={"Real Estate"}></Sector>
                    <Sector icon={faDumbbell} field={"Fitness"}></Sector>
                    <Sector icon={faHome} field={"Rec/Training Centers"}></Sector>
                    <Sector icon={faColonSign} field={"Luxury Goods"}></Sector>
                    <Sector icon={faServer} field={"IoT Tech"}></Sector>
                </div>
            </div>
        </div>
    )
}