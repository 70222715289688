import { Link } from "react-scroll";
import { useState } from "react";

export default function MarketingAttribution() {
  return (
    <body className="bg-slate-700 min-h-screen text-secondary font-sedan">
      {/* Intro icon */}
      <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
        {/* Background image with gradient overlay */}
        <img
          src="/images/attribution-banner.jpg"
          className="h-[100%] w-[100%] object-cover bg-blend-overlay"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900 opacity-50"></div>

        <div className="w-[30%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12 border-l-4 border-complementary h-fit  px-8">
          <h1 className="text-6xl max-md:text-5xl leading-[75px] font-semibold text-white">
            Marketing Attribution
          </h1>
        </div>
      </div>

      <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center text-white">
        <h1 className="text-5xl max-md:text-4xl font-bold text-center">
          Attribution: Gain Deeper Insights into Your Return on Investment
        </h1>
        <h1 className="text-2xl font-semibold text-center max-md:text-xl">
          What is attribution and how can it benefit your business?
        </h1>
        <p className="text-lg max-md:text-sm">
          Marketing attribution is the process of tracing a customer's journey to determine which marketing efforts effectively contributed to a sale. It provides valuable insights into optimizing your marketing investments, understanding consumer behavior, and tailoring your messaging for maximum impact.
        </p>
        <p className="text-lg max-md:text-sm">
          While attribution can be complex, it is essential for businesses of all sizes. By understanding which marketing channels and tactics drive the most results, you can allocate your budget more effectively and avoid wasted spending.
        </p>
        <p className="text-lg max-md:text-sm">
          Powerful attribution tools like Attribution, Bizible, Hive9, Windsor.ai, C3 Metrics, and Dreamdata can help you navigate the complexities of attribution and gain valuable insights into your marketing performance.
        </p>
      </div>
    </body>
  );
}