export default function GrowthStages() {
    return (
      <body className="bg-slate-700 min-h-screen text-secondary font-sedan">
        {/* intro icon */}
        <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
          <img
            src="/images/growth-banner.jpg"
            className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"
            style={{
              backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))",
            }}
          />
  
          <div className="w-[30%] max-md:w-[80%] absolute justify-center top-0 bottom-0 left-24 m-auto flex flex-col max-md:left-10 gap-12 border-l-4 border-complementary h-fit  px-8">
            <h1 className="text-6xl max-md:text-4xl leading-[75px] font-semibold ">
              Business Growth Stages: A Strategic Guide
            </h1>
          </div>
        </div>
  
        <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center text-lg max-md:text-sm">
          <h1 className="text-5xl max-md:text-4xl font-bold text-center text-white">
            <span className="text-sky-200">Identify</span> the Optimal Marketing Strategy for Your Business <span className="text-sky-200">Growth</span> Stage
          </h1>
          <h1 className="text-2xl max-md:text-xl text-center font-semibold text-white">
            Navigating the Key Phases of Business Scaling
          </h1>
  
          <h1 className="text-3xl font-semibold self-start max-md:text-2xl text-slate-200">
            Initial Stage: Establishing a Strong Foundation
          </h1>
          <p className="text-lg max-md:text-sm text-white">
            As your business enters its initial stage, focus on solidifying your brand identity, value proposition, and platform performance. Attract a wider customer base by increasing brand visibility and engagement while meticulously analyzing the customer journey. Remember, agility is key at this stage, as limited resources can make missteps costly.
          </p>
  
          <h1 className="text-3xl font-semibold self-start max-md:text-2xl text-slate-200">
            Second Stage: Accelerating Growth
          </h1>
          <p className="text-lg max-md:text-sm text-white">
            As your business expands, prioritize customer acquisition and retention. Leverage advanced databases and automation tools to gain deeper insights into customer behavior and create targeted campaigns that drive engagement and conversions. While growth brings financial resources, maintain a focus on attribution to ensure marketing initiatives deliver optimal results. This stage often requires team expansion and a strong organizational culture.
          </p>
          <p className="self-start text-lg max-md:text-sm text-white">
            Examples of companies in this growth phase include tech firms such as Stripe, Bopple, and Jarvis.
          </p>
  
          <h1 className="text-3xl font-semibold self-start max-md:text-2xl text-slate-200">
            Third Stage: Expanding into New Markets
          </h1>
          <p className="text-lg max-md:text-sm text-white">
            Once you've established success in one region, consider expanding into new markets. This requires careful planning and adherence to industry best practices to avoid costly mistakes. Maintaining a consistent brand identity and understanding the target market's perception of your brand are crucial factors to consider.
          </p>
          <p className="text-lg max-md:text-sm text-white">
            Evaluate different expansion strategies, such as independent expansion, partnerships, or acquisitions. Each approach has unique implications, so carefully consider the potential benefits and challenges. Companies like Segway and Razor, initially dominant in Europe, have successfully expanded into the U.S. and other territories.
          </p>
  
          <h1 className="text-3xl font-semibold self-start max-md:text-2xl text-slate-200">
            Fourth Stage: Maturity and Renewal
          </h1>
          <p className="text-lg max-md:text-sm text-white">
            At this stage, your business is well-established but may face challenges like market saturation and competition. To maintain relevance and drive renewal, stay updated on market trends, competitor movements, and the evolving needs of your customers.
          </p>
          <p className="self-start text-lg max-md:text-sm text-white">
            Contact Agency Marketing today to discover how we can support you through every stage of your business growth.
          </p>
        </div>
      </body>
    );
  }