export default function BrandStatement() {
  return (
    <div className="bg-gray-700 relative min-h-screen text-white flex justify-center items-center py-2">
      <div className="flex flex-col gap-8 p-8 px-20 max-md:p-4">
        <h1 className="text-7xl font-bold max-md:text-4xl text-center">
          <span className="text-white">Marketing</span> Solutions to <span className="text-white">Expand</span> Your <span className="text-white">Brand</span>
        </h1>
        <h2 className="text-4xl max-md:text-lg text-center">
          How can effective marketing strategies propel my business forward?
        </h2>
        <p className="text-2xl max-md:text-base">
          Regardless of your brand's stage of development, choosing the right marketing strategy is paramount to achieving your business objectives. KLYR Media offers tailored solutions that align seamlessly with your company's goals. Our focus on efficiency ensures that your campaigns deliver maximum returns while minimizing costs.
        </p>
        <p className="text-2xl max-md:text-base">
          Your marketing approach is influenced by various factors, including business size, growth stage, advertising budget, and specific goals. However, to ensure that your current strategies are yielding optimal results, a comprehensive evaluation is essential.
        </p>
      </div>
    </div>
  );
}
