export default function MarketingAutomation(){
    return(
        <body className=" bg-gray-700 min-h-screen text-secondary font-sedan">
            {/* intro icon */}
                <div className="relative h-screen flex justify-center items-center">
                
                    <img src="/images/automation-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
                    
                    
                    <div className="w-[30%] max-md:w-[80%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12 border-l-4 border-sky-200 h-fit  px-8 ">
                        <h1 className="text-6xl max-md:text-5xl leading-[75px] font-semibold text-black">  Marketing Automation</h1>
                    </div>
                </div>
                <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center  text-slate-200">
                    <div>
                <h1 className="text-5xl max-md:text-4xl font-bold text-center">
                    <span className="text-gray-200">Marketing Automation:</span> A Strategic Approach to Enhanced Productivity
                </h1>
                <h1 className="text-2xl max-md:text-xl text-center font-semibold">
                    Boost Business Efficiency and Drive Results with Automation
                </h1>
                <p className="text-lg max-md:text-sm">
                    Marketing automation streamlines previously manual and time-consuming tasks like email campaigns and appointment reminders. By leveraging sophisticated software with AI and machine learning (ML) capabilities, automation delivers targeted content with greater effectiveness and faster execution.
                </p>
                <p className="text-lg max-md:text-sm">
                    This approach fosters personalized user engagement by tracking behavior, interests, and intent. Data gathered by the automation software continuously optimizes campaign performance, ensuring your messaging resonates with the right audience.
                </p>
                <p className="text-lg max-md:text-sm">
                    Marketing automation empowers businesses to achieve their goals by facilitating effective customer engagement and driving desired results and conversions. Tools like HubSpot, Mailchimp, Monday.com, ActiveCampaign, and Sendinblue provide a powerful competitive advantage.
                </p>
                </div>
            </div>
        </body>
    )
}