import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Sector({icon, field}){
    return(
        <div className="flex flex-col gap-8 text-center items-center justify-center bg-transparent">
            <FontAwesomeIcon className="h-10 max-md:h-6" icon={icon}></FontAwesomeIcon>
            <h3 className="text-xl">{field}</h3>
        </div>
        
    )
}