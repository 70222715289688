import Testimonial from "./Testimonial";

export default function Testimonials(){
    return(
        <div name="Testimonials" className="bg-slate-700 relative min-h-screen text-secondary flex justify-center items-center">
            
            <div className=" justify-center items-center flex flex-col gap-12 p-12 ">
                <h1 className="text-5xl font-bold max-md:text-4xl text-slate-300">Testimonials</h1>
                <h1 className="text-3xl font-semibold text-complementary max-md:text-xl">What our clients say about us</h1>
                <div className="grid grid-cols-3 max-md:grid-cols-1 gap-24 max-md:gap-16 px-0 items-start text-slate-200">
                    <Testimonial brand={"Novanta"} comment={"Agency Marketing collaborated with us to craft a sophisticated marketing plan and a tactical approach for introducing a new product. They provided a top-tier plan and triumphed in deploying the strategy throughout our organization."}></Testimonial>
                    <Testimonial brand={"Luminar Systems"} comment={"Agency Marketing has offered clear guidance and strategic insights to enhance our business growth, successfully setting up marketing teams for implementation. They have not only handled our expectations but have also surpassed them in both digital and traditional marketing campaigns with outstanding results."}></Testimonial>
                    <Testimonial brand={"Clarion"} comment={"Agency stands out as a motivated marketing expert with a unique talent for positively questioning traditional viewpoints. He possesses a pronounced and evident enthusiasm for marketing, coupled with the dedication to achieve exceptional outcomes."}></Testimonial>
                </div>
            </div>
        </div>
    )
}