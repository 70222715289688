import { faChartSimple, faPerson, faStore, faUsers } from "@fortawesome/free-solid-svg-icons";
import Service from "./Service";

export default function Services() {
  return (
    <div name="Services" className="bg-gray-800 relative min-h-screen text-white flex justify-center items-center">
      <div className="justify-center items-center flex flex-col gap-12 p-12 max-md:p-4">
        <h1 className="text-5xl max-md:text-4xl font-bold">Services</h1>
        <h1 className="text-3xl max-md:text-xl font-semibold text-white">
          Agency Marketing
        </h1>
        <div className="grid grid-cols-3 gap-8 gap-x-24 max-md:gap-x-4 max-md:grid-cols-1 items-start">
          <Service
            tool={"Marketing Analysis and Strategic Planning"}
            desc={
              "Our comprehensive marketing analysis and strategic planning process lays the foundation for your success."
            }
            icon={faChartSimple}
          ></Service>
          <Service
            tool={"Marketing Plan Execution"}
            desc={
              "We execute your marketing plan flawlessly, ensuring your business is set up for growth and scalability."
            }
            icon={faStore}
          ></Service>
          <Service
            tool={"Marketing Management and Consulting"}
            desc={
              "Our expert team provides ongoing management and consulting to guide your marketing efforts."
            }
            icon={faUsers}
          ></Service>
        </div>
      </div>
    </div>
  );
}