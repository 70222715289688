export default function Contact(){
  return (
      <div name="Contact" className="px-28 py-8 max-md:px-4 bg-gray-600 font-sedan min-h-screen flex-col justify-center items-center flex text-slate-200">
              
              <form method="POST" action="https://getform.io/f/amdplzvb" className="justify-center items-center grid grid-cols-2 w-[80%] max-md:w-[100%] gap-2 max-md:gap-2 max-md:gap-y-4 max-md:grid-cols-1">
                  <h1 className=" text-5xl font-bold col-span-2 text-center max-md:text-3xl">Contact Us</h1>
                  <h1 className=" text-xl col-span-2 text-center max-md:text-md text-opacity-70">Ready to elevate your marketing strategy? Get in touch with us today!</h1>
                  <div className="w-[100%] flex flex-col gap-2">
                      <h3 className="text-sm">Name</h3>
                      <input name = "name" className="w-[100%] border-opacity-20 border-secondary border-2 bg-opacity-0 bg-white p-2 text-md rounded-md  max-md:text-sm" type="text" placeholder="Enter your name..."></input>
                  </div>
                  <div className="w-[100%] flex flex-col gap-2">
                      <h3 className="text-sm">Email</h3>
                      <input type="email" name = "email" className="w-[100%] border-opacity-20 border-secondary border-2 bg-opacity-0 bg-white p-2 text-md rounded-md  max-md:text-sm"  placeholder="Enter your email..."></input>
                  </div>
                  <div className="w-[100%] flex flex-col gap-2">
                      <h3 className="text-sm">Number</h3>
                      <input name = "number" className="w-[100%] border-opacity-20 border-secondary border-2 bg-opacity-0 bg-white p-2 text-md rounded-md  max-md:text-sm" type="text" placeholder="Enter your number..."></input>
                  </div>
                  <div className="w-[100%] flex flex-col gap-2">
                      <h3 className="text-sm">Budget</h3>
                      <select name="budget" className="w-[100%] border-opacity-20 border-secondary border-2 bg-opacity-0 bg-white p-2 text-md rounded-md  max-md:text-sm font-sans">
                          <option className="text-primary" value="">Select Budget...</option>
                          <option className="text-primary" value="Below $10k">Below $10k</option>
                          <option className="text-primary" value="$10k-50k">$10k-50k</option>
                          <option className="text-primary" value="$50k-100k">$50k-100k</option>
                          <option className="text-primary" value="$100k+">$100k+ </option>
                      </select>
                      {/* <input name = "name" className="w-[100%] border-opacity-20 border-secondary border-2 bg-opacity-0 bg-white p-2 text-md rounded-md text-secondary max-md:text-sm" type="text" placeholder="Enter your name..."></input> */}
                  </div>
                  <div className="w-[100%] flex flex-col gap-2 col-span-2">
                      <h3 className="text-sm">Message</h3>
                      <textarea className=" border-opacity-20 bg-opacity-0 bg-white border-secondary border-2 p-2 rounded-md h-[200px]  max-md:text-sm" type="text" placeholder="Enter project details..." name = "message"></textarea>    
                  </div>
                  
                  
                  <button className="col-span-2 text-md p-3 bold rounded-lg bg-gray-700 text-primary hover:bg-secondary font-semibold duration-500" type="submit">Submit</button>
                  
          </form> 

      </div>
  )
}