import { faDashboard ,faStar} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Testimonial({brand, comment}){
    return(
        
        <div className="flex flex-col gap-8 justify-center items-center text-center ">
            <h1 className="text-2xl font-semibold max-md:text-xl">{brand}</h1>
            <h3 className="text-lg max-md:text-sm">{comment}</h3>
            <div className="flex justify-center items-center gap-2">
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>

            </div>
        </div>
    )
}