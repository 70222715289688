// import React, { useState } from 'react';
// import axios from 'axios';

// const Analytics = () => {
//   const [websiteId, setWebsiteId] = useState('');
//   const [analyticsData, setAnalyticsData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const fetchAnalyticsData = async () => {
//     console.log('Sending View ID:', websiteId);  // Check the View ID being sent
//     setLoading(true);
//     setError('');
//     setAnalyticsData(null);
  
//     try {
//       console.log('Button clicked');
//       const response = await axios.post('http://localhost:3001/api/analytics', {
//         viewId: websiteId,
//       });
  
//       console.log('Response received:', response.data);  // Log the response data
//       setAnalyticsData(response.data);
//     } catch (err) {
//       console.error('Error fetching analytics data:', err.response ? err.response.data : err.message);
//       setError('Failed to fetch analytics data. Please check the View ID and try again.');
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   return (
//     <div className="min-h-screen flex flex-col items-center justify-center bg-gray-400">
//       <div className="bg-white shadow-md rounded p-8 max-w-2xl w-full">
//         <h1 className="text-3xl font-extrabold text-center mb-6">See How Your Website is Doing</h1>

//         {/* Input for Website ID */}
//         <div className="my-4">
//           <input
//             type="text"
//             value={websiteId}
//             onChange={(e) => setWebsiteId(e.target.value)}
//             placeholder="Enter Google Analytics View ID"
//             className="border border-rose-300 rounded px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-rose-500"
//           />
//           <button
//             onClick={fetchAnalyticsData}
//             className="bg-rose-600 text-white mt-4 w-full py-2 rounded hover:bg-rose-700 disabled:bg-rose-200"
//             disabled={!websiteId || loading}
//           >
//             {loading ? 'Fetching...' : 'Get Analytics'}
//           </button>
//         </div>

//         {/* Error Message */}
//         {error && <p className="text-red-500 text-center">{error}</p>}

//         {/* Analytics Data */}
//         {analyticsData && (
//           <div className="mt-6">
//             <h2 className="text-2xl font-semibold text-center mb-4">Analytics Data</h2>
//             <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
//               {analyticsData.reports[0].data.rows.map((row, index) => (
//                 <div key={index} className="bg-gray-50 border border-gray-200 p-4 rounded shadow-sm">
//                   <p><strong>Date:</strong> {row.dimensions ? row.dimensions[0] : 'N/A'}</p>
//                   <p><strong>Sessions:</strong> {row.metrics ? row.metrics[0].values[0] : 'N/A'}</p>
//                   <p><strong>Pageviews:</strong> {row.metrics ? row.metrics[0].values[1] : 'N/A'}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         {/* Loading Spinner */}
//         {loading && (
//           <div className="flex justify-center mt-4">
//             <svg className="animate-spin h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//               <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//               <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
//             </svg>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Analytics;
import React, { useState } from 'react';
import axios from 'axios';

const Analytics = () => {
  const [websiteId, setWebsiteId] = useState('');
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchAnalyticsData = async () => {
    setLoading(true);
    setError('');
    setAnalyticsData(null);

    try {
      const response = await axios.post('https://api.klyrmedia.com/api/analytics', {
        viewId: websiteId,
      });

      setAnalyticsData(response.data);
    } catch (err) {
      console.error('Error fetching analytics data:', err);
      setError('Failed to fetch analytics data. Please check the View ID and try again.');
    } finally {
      setLoading(false);
    }
  };

  // Calculate averages
  const calculateAverages = (data) => {
    let totalSessions = 0;
    let totalPageviews = 0;
    const rows = data.reports[0].data.rows;

    rows.forEach((row) => {
      totalSessions += parseInt(row.metrics[0].values[0], 10) || 0;
      totalPageviews += parseInt(row.metrics[0].values[1], 10) || 0;
    });

    const averageSessions = (totalSessions / rows.length).toFixed(2);
    const averagePageviews = (totalPageviews / rows.length).toFixed(2);

    return { averageSessions, averagePageviews };
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-400">
      <div className="bg-white shadow-md rounded p-8 max-w-7xl w-full mx-4">
        <h1 className="text-3xl font-extrabold text-center mb-6">See How Your Website is Doing</h1>

        {/* Input for Website ID */}
        <div className="my-4">
          <input
            type="text"
            value={websiteId}
            onChange={(e) => setWebsiteId(e.target.value)}
            placeholder="Enter Google Analytics View ID or 'test' for mock data"
            className="border border-rose-300 rounded px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-rose-500"
          />
          <button
            onClick={fetchAnalyticsData}
            className="bg-rose-600 text-white mt-4 w-full py-2 rounded hover:bg-rose-700 disabled:bg-rose-200"
            disabled={!websiteId || loading}
          >
            {loading ? 'Fetching...' : 'Get Analytics'}
          </button>
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500 text-center">{error}</p>}

        {/* Averages Display */}
        {analyticsData && (
          <div className="mt-4">
            {calculateAverages(analyticsData) && (
              <div className="bg-gray-100 border border-gray-200 p-4 rounded shadow-md mb-6">
                <h2 className="text-xl font-semibold text-center mb-2">Averages</h2>
                <p className="text-lg"><strong>Average Sessions:</strong> {calculateAverages(analyticsData).averageSessions}</p>
                <p className="text-lg"><strong>Average Pageviews:</strong> {calculateAverages(analyticsData).averagePageviews}</p>
              </div>
            )}
          </div>
        )}

        {/* Analytics Data */}
        {analyticsData && (
          <div className="mt-6">
            <h2 className="text-2xl font-semibold text-center mb-4">Analytics Data</h2>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {analyticsData.reports[0].data.rows.map((row, index) => (
                <div key={index} className="bg-gray-50 border border-gray-200 p-6 rounded shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <p className="text-lg"><strong>Date:</strong> <span className="font-medium">{row.dimensions ? row.dimensions[0] : 'N/A'}</span></p>
                  <p className="text-lg"><strong>Sessions:</strong> <span className="font-medium">{row.metrics ? row.metrics[0].values[0] : 'N/A'}</span></p>
                  <p className="text-lg"><strong>Pageviews:</strong> <span className="font-medium">{row.metrics ? row.metrics[0].values[1] : 'N/A'}</span></p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Loading Spinner */}
        {loading && (
          <div className="flex justify-center mt-4">
            <svg className="animate-spin h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
