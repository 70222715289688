export default function About() {
  return (
    <div name="About" className="bg-gray-800 relative min-h-screen text-white grid grid-cols-2 max-lg:grid-cols-1">
      <img
        src="/images/about-banner.jpg"
        className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"
        style={{
          backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))",
        }}
      />

      <div className="justify-center items-center flex flex-col gap-8 p-8 max-lg:py-6 max-lg:p-4">
        <h1 className="text-6xl max-md:text-4xl font-semibold self-start">
          <span className="text-white">|</span> KYLR Media
        </h1>
        <p className="text-2xl max-lg:text-xl text-slate-200 leading-relaxed">
          Welcome to KYLR Media, where innovation meets excellence. We are a dynamic team of marketing experts dedicated to propelling your brand to new heights. Our strategic approach and creative prowess deliver exceptional results that exceed expectations.
        </p>
        <p className="text-2xl max-lg:text-xl text-slate-200 leading-relaxed">
          Established in 2023 in the heart of New York City, KYLR Media has rapidly emerged as a leading force in the marketing industry. We specialize in driving sustainable growth for our clients by optimizing sales, increasing website traffic, and expanding brand awareness. Our innovative strategies resonate with diverse audiences, and we are committed to helping both established enterprises and emerging startups thrive in the competitive landscape of digital and traditional marketing.
        </p>
      </div>
    </div>
  );
}
