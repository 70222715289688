// import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import MarketingAutomation from './pages/MarketingAutomation';
import MarketingAttribution from './pages/MarketingAttribution';
import GrowthStages from './pages/GrowthStages';
import MarketingResearch from './pages/MarketingResearch';
import Analytics from './pages/Analytics';


function App() {
  return (
    // <ClerkProvider publishableKey={process.env.VITE_CLERK_PUBLISHABLE_KEY}>
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path='/' Component={Home}></Route>
          <Route path='/blog/marketing-automation' Component={MarketingAutomation}></Route>
          <Route path='/blog/marketing-attribution' Component={MarketingAttribution}></Route>
          <Route path='/blog/growth-stages' Component={GrowthStages}></Route>
          <Route path='/blog/marketing-research' Component={MarketingResearch}></Route>
          <Route path='/analytics' Component={Analytics}></Route>
        </Routes>
        <Contact></Contact>
      </BrowserRouter>
    // </ClerkProvider>
  );
}

export default App;
