import About from "../components/Home/About";
import BrandStatement from "../components/Home/BrandStatement";
import Intro from "../components/Home/Intro";
import Sectors from "../components/Home/Sectors";
import Services from "../components/Home/Services";
import Testimonials from "../components/Home/Testimonials";

export default function Home(){
    return(
        <body className="bg-gray-500 min-h-screen text-secondary font-sedan">
            <Intro></Intro>
            <BrandStatement></BrandStatement>
            <About></About>
            <Services></Services>
            <Sectors></Sectors>
            <Testimonials></Testimonials>
        </body>
    )
}